import React from 'react'
import { render } from 'react-dom'
import { Form, Field } from 'react-final-form'
import styled from 'styled-components'


const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const onSubmit = async values => {
  await sleep(300)
  window.alert("Thank you for your submission.")
}

const Wrapper = styled.div`
  width:100%;
  display:flex;
  flex-direction: column;
  align-items:space-between;
  padding-right:2em;
      @media (max-width: 700px) {
    padding: 0 2em;
  }
`

const Button = styled.div`
  font-family: Roboto;
  font-size:1em;
  letter-spacing:2px;
  text-transform:uppercase;
  font-weight:900;
  color:white;
  background-color:rgb(227, 103, 78);
  border-radius:25px;
  width:100px;
  align-self:flex-start;
  text-align:center;
  line-height:20px;
  &:hover { 
    background-color:rgb(214, 147, 102);
  }
`

const Label = styled.label`
  font-family:lato;
  font-weight:400;
`

const ContactWrapper = styled.div`
  display:flex;
  flex-direction:column;
  margin-top:20px;
  & > input {
  border: 1px solid #dddbdb;
  border-radius:100px;
  padding: 15px 20px;
  }
& textarea {
  border: 1px solid #dddbdb;
  border-radius:30px;
  padding: 50px 20px;
  }
& buttons {
  margin-top:20px;
}
`

const Title = styled.div`
  padding: 30px 0;
  font-family: "Advent Pro", Arial, Helvetica, sans-serif;
  color: rgb(227, 103, 78);
  &:hover{
  color: rgb(227, 103, 78); }
  font-size:2em;
  font-stretch:100%;
  font-weight:600;
  text-align: center;
    align-self:flex-start;
    transition:0.2s;
`

const SubTitle = styled.div`
  // padding: 20px;
  font-family: "Advent Pro", Arial, Helvetica, sans-serif;
  color: ${props => props.color};
  &:hover{
  color: #454952; }
  font-size:1em;
  font-stretch:100%;
  font-weight:700;
  text-align: center;
    align-self:center;
    transition:0.2s;
    line-height:1.5;
`

const Text = styled.div`
  padding: 20px;
  font-family: Lato;
  color: #585858;
  font-size:1em;
  font-stretch:100%;
  font-weight:400;
  text-align: center;
    align-self:center;
    line-height:2;
    `

const ContactForm = () => (
  <Wrapper>
    <Title> Send Us A Quick Email </Title>
    <Form
      onSubmit={onSubmit}
      initialValues={{ }}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit}>
          <ContactWrapper>
            <Label>Your Name: (Required)</Label>
            <Field
              name="firstName"
              component="input"
              type="text"
            />
          </ContactWrapper>
          <ContactWrapper>
            <Label>Your Email: (Required)</Label>
            <Field
              name="email"
              component="input"
              type="email"
            />
          </ContactWrapper>
          <ContactWrapper>
            <Label>Subject</Label>
            <Field name="notes" component="input" type="text" />
          </ContactWrapper>
          <ContactWrapper>
            <Label>Message:</Label>
            <Field name="notes" component="textarea" />
          </ContactWrapper>
          <ContactWrapper className="buttons">
            <Button type="submit" disabled={submitting || pristine}>
              Send
            </Button>
          </ContactWrapper>
          {/*<pre>{JSON.stringify(values, 0, 2)}</pre>*/}
        </form>
      )}
    />

    </Wrapper>
)

export default ContactForm