import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from 'styled-components'
import heroImage from '../images/go-bounce.jpg'
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'

const Wrapper = styled.div`
  width:100%;
  // background-image: url(${heroImage});
  background-size:cover;
      height: 40vh;
      min-height:370px;
    background-position: center bottom;
    // background-size: cover;
    background-repeat: no-repeat;
    display:flex;
    flex-direction: column;+
    align-self:center;
    justify-content:center;
    background-attachment: fixed;
    text-transform:uppercase;
    line-height:4em;

`
const HeroText = styled.div`
  font-family: "Advent Pro", Arial, Helvetica, sans-serif;
  color: #ffffff;
  font-size:1.5em;
  font-stretch:100%;
  font-weight:700;
  text-align: center;
    align-self:center;

`

const HeroTitle = styled.div`
  font-family: "Advent Pro", Arial, Helvetica, sans-serif;
  color: #E2674E;
  font-size:3em;
  font-stretch: 100%;
  font-weight:700;
  text-align:center;
      align-self:center;
`
const TextBox = styled.div`
width:100%;
 position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const HeroBanner = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "contact-banner.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2080) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data =>{
      const imageData = data.placeholderImage.childImageSharp.fluid;
      return (
      <Wrapper>
      <BackgroundImage Tag="section"
      className={className}
                           fluid={imageData}
                           backgroundColor={`#040e18`}
          >
       {/* <Img fluid={data.placeholderImage.childImageSharp.fluid} style="height:100%;width:100%;"/>*/}
        <TextBox>
        <HeroTitle>Contact Us</HeroTitle></TextBox>
        </BackgroundImage>
        </Wrapper> 
        )
    }
      }
      />
)

const StyledBackgroundSection = styled(HeroBanner)`
  width:100%; height:100%;
    &:after,&:before{ 
    background-size:contain;
    // background-repeat:no-repeat;
    background-position: center bottom;
  } 
  `

export default StyledBackgroundSection