import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import HeroBanner from "../components/HeroBanner"
import CActionBanner from "../components/CActionBanner"
import ContactBanner from "../components/ContactBanner"

import styled from 'styled-components'
import ContactInfo from "../components/ContactInfo"
import AssocBox from "../components/AssocBox"
import ContactBox from "../components/ContactBox"

const ProjectsWrapper = styled.div`
height:50vh;
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: 1fr 1fr;
  padding: 20px;
  // @media (max-width: 1200px) {
  //   grid-gap: 3rem;
  // }
  // @media (max-width: 900px) {
  //   grid-template-columns: 1fr;
  //   grid-gap: 2rem;
  // }
`
const Wrapper = styled.div`
`

const ContactPage = () => (
  <Layout>
  <SEO title="Contact" keywords={['commercial flooring', 'trevor wharton', 'york', 'flooring contractors', 'flooring installation', 'vinyl flooring']} />
    <ContactBanner />
  <Wrapper>
  
    <ContactInfo />
</Wrapper>
  <ContactBox />
 </Layout>
)

export default ContactPage

export const query = graphql`
  query {
    coverImage: file(
      relativePath: { regex: "/alexander-andrews-260988-unsplash-edited/" }
    ) {
      childImageSharp {
        fluid(
          maxWidth: 800
          quality: 80
          duotone: { highlight: "#ffffff", shadow: "#663399" }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`