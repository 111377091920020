import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from 'styled-components'
import solutionImage1 from "../images/tailored-contracts.jpg"
import solutionImage2 from "../images/commercial-packages.jpg"
import solutionImage3 from "../images/cleaning-services.png"
import ContactForm from "../components/BareContactForm"

const Wrapper = styled.div`
  width:100%;
      min-height: 30vh;
      height:auto;
      background-color:white;
    display:grid;
    grid-template-columns: 1fr .5fr 3fr;
        @media (max-width: 700px) {
          display:flex;
    flex-direction:column;
    justify-content: space-evenly;
  }
    align-self:center;
    justify-content:space-evenly;
    // line-height:4em;
    transition: 0.2s;
    padding-top:50px;
    padding-bottom:50px;
`
const Title = styled.div`
  padding: 30px;
  font-family: "Advent Pro", Arial, Helvetica, sans-serif;
  color: rgb(227, 103, 78);
  &:hover{
  color: rgb(227, 103, 78); }
  font-size:2em;
  font-stretch:100%;
  font-weight:600;
  text-align: center;
    align-self:center;
    transition:0.2s;
`

const SubTitle = styled.div`
  // padding: 20px;
  font-family: "Advent Pro", Arial, Helvetica, sans-serif;
  color: ${props => props.color};
  &:hover{
  color: #454952; }
  font-size:1em;
  font-stretch:100%;
  font-weight:700;
  text-align: center;
    align-self:center;
    transition:0.2s;
    line-height:1.5;
`

const Text = styled.div`
  padding: 20px;
  font-family: Lato;
  color: #585858;
  font-size:1em;
  font-stretch:100%;
  font-weight:400;
  text-align: center;
    align-self:center;
    line-height:2;
`

const WWrapper = styled.div`
display:flex;
  flex-direction:row;
  justify-content: space-evenly;
    @media (max-width: 700px) {
    flex-direction:column;
  }
padding: 25px 25px 15px 25px;
`
const Box = styled.div`
display:flex;
flex-direction: column;
overflow:hidden;
  @media (max-width: 700px) {
    width:100%;
        margin-bottom: 20px;

  }
  background-color: #f2f5f8;
  padding: 1em;
  width:30%;
    //  display:flex;
    // flex-direction: column;
    // align-self:center;
    // justify-content:center;
    // line-height:4em;
    transition: 0.2s;
`

const TextBox = styled.div`
  background-color:white;
  width:100%;
     display:flex;
    flex-direction: column;
    align-self:center;
    justify-content:center;
    // line-height:4em;
    transition: 0.2s;
`

const Image = styled.img`
  overflow:hidden;
  width:100%;
  height:100%;
  &:hover {
    transform: scale(1.5);
  }
  transition:3s;
`

const Boxes = ({ children }) => (
<Wrapper>
  <TextBox> 
    <Title> Get in touch </Title>
    <Text>For any enquiries please feel free to contact us on:
      <SubTitle>Phone:</SubTitle> 01904 849450
      <SubTitle>Email:</SubTitle> trevor@twcarpets.co.uk
      </Text>
    <Title> Address </Title>
    <Text>Unit 5, 35 Hospital Fields Road,
Fulford Business Park,
Fulford, York
YO10 4DZ
      </Text>
  </TextBox>
  <div> </div>
    <ContactForm />

  </Wrapper>
)


export default Boxes
